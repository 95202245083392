<template>
  <div class="flex-col items-end md:flex">

    <div class="mb-16 md:w-2/3 md:pl-8">
      <h1 class="mb-2 text-4xl text-gray-700">Autokredit- & Leasingvertrag widerrufen</h1>
      <h2 class="mb-4 text-4xl">Sie wollen widerrufen? Finden Sie es heraus. Starten Sie den kostenlosen Schnelltest.</h2>

      <a href="#formular">
        <button
          class="px-4 py-3 mr-4 text-base transition duration-300 rounded-none bg-orange hover:bg-black hover:text-white"
          type="button"
        >Zum Schnelltest <span class="ml-1">→</span></button>
      </a>
    </div>

    <div class="md:flex">
      <div class="w-32 mb-6 md:w-1/3 md:pr-8">
        <img src="../assets/hsw_bildmarke.svg" alt="logo" class="w-full">
      </div>
      <div class="md:w-2/3 md:pl-8">
        <Seo :banks="banks" class="mb-16" />
        <Form :banks="banks" id="formular" />
      </div>
    </div>

  </div>
</template>

<script>
import Form from '../components/Form.vue'
import Seo from '../components/Seo.vue'

export default {
  name: 'Home',
  data: function() {
    return {
      banks: [
        'akf Bank FCA Bank',
        'ALD Lease Finanz GmbH',
        'Alfa Romeo Bank',
        'Audi-Bank',
        'Auto Europa Bank',
        'Bank Deutsches Kraftfahrzeuggewerbe',
        'Bank11',
        'BMW Bank',
        'Deutschland',
        'Fiat Bank',
        'Ford Bank',
        'Honda Bank',
        'Hyundai Capital Bank',
        'Jaguar Bank',
        'Kia Finance',
        'Lancia Bank',
        'Land Rover Bank',
        'Maserati Bank',
        'Mercedes-Benz Bank',
        'MKG Bank (Mitsubishi)',
        'Nissan Bank',
        'Opel Bank',
        'Peugeot Bank',
        'Porsche Bank',
        'Renault Bank',
        'Santander Consumer Bank',
        'Seat Bank',
        'Sixt Leasing',
        'Skoda Bank',
        'Targobank',
        'Toyota Kreditbank',
        'VW Bank',
        'Andere Bank',
      ]
    }
  },
  components: {
    Form,
    Seo,
  },
}
</script>
