<template>
  <div id="app" class="container mx-auto lg:px-8">
    <header class="flex justify-between p-6">
      <a href="https://hswrecht.de" target="_blank" rel="noopener noreferrer">
        <img class="h-8" alt="logo" src="./assets/hsw_wortmarke.svg" />
      </a>

      <nav class="hidden lg:block">
        <Links :vertical="true" />
      </nav>

      <a
        @mouseover="toggleNumber($event)"
        class="hidden text-gray-600 hover:text-black lg:block"
        href="tel: 0157 71366534"
      >Strafrecht-Notruf</a>

      <div class="lg:hidden">
        <svg class="stroke-current" height="20" width="20" @click="toggleDropdown()">
          <path d="M0,2 20,2"></path>
          <path d="M0,10 20,10"></path>
          <path d="M0,18 12,18"></path>
        </svg>
        <div v-show="dropdownOpen" class="container fixed inset-0 mx-auto mt-16">
          <div class="h-full px-8 py-6 bg-white">
            <Links />
          </div>
        </div>
      </div>
    </header>

    <main class="p-6" style="min-height: 50vh;">
      <img src="./assets/296714126.jpg" alt="Header" class="object-cover w-full mb-12 h-76" />

      <router-view></router-view>
    </main>

    <footer class="p-6 pb-16 mt-32">
      <div class="md:flex">
        <div class="w-full pb-10 md:pr-6 md:pb-0">
          <div class="pt-8 border-gray-400 border-t-1">
            <p class="mb-1 font-semibold">Kontakt</p>

            <ul>
              <li>
                <span class="inline-block w-16 text-sm text-gray-700">Tel</span> <a href="tel: 0241 504052">0241 504052</a>
              </li>
              <li><span class="inline-block w-16 text-sm text-gray-700">Fax</span> 0241 504054</li>
              <li>
                <span class="inline-block w-16 text-sm text-gray-700">Mail</span> <a href="mailto:widerruf@hswrecht.de">widerruf@hswrecht.de</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full pb-10 md:px-6 md:pb-0">
          <div class="pt-8 border-gray-400 border-t-1">
            <p class="mb-1 font-semibold">Adresse</p>
            <p class="mb-4">Augustastraße 1<br> 52070 Aachen</p>

            <a
              class="underline"
              href="https://www.google.de/maps/place/Augustastra%C3%9Fe+1+52070+Aachen"
              rel="noopener noreferrer"
              target="_blank"
            >Anfahrtsbeschreibung</a>
          </div>
        </div>
        <div class="w-full pb-10 md:pl-6 md:pb-0">
          <div class="pt-8 border-gray-400 border-t-1">
            <p class="mb-1 font-semibold">Bürozeiten</p>

            <p class="text-gray-700">Montag bis Freitag</p>
            <p>08:30–13:00 14:00–18:00</p>
          </div>
        </div>
      </div>

      <ul class="flex mt-8">
        <li class="mr-4">
          <a href="https://www.hswrecht.de/datenschutz" class="text-gray-600 hover:text-black">Datenschutz</a>
        </li>
        <li class="mr-4">
          <a href="https://www.hswrecht.de/impressum" class="text-gray-600 hover:text-black">Impressum</a>
        </li>
      </ul>
    </footer>

  </div>
</template>

<script>
import Links from './components/Links.vue'

export default {
  name: 'App',
  components: {
    Links
  },
  data: function() {
    return {
      dropdownOpen: false,
    }
  },
  methods: {
    toggleNumber: function(event) {
      if (event.target.innerHTML.includes('0')) {
        event.target.innerHTML = 'Strafrecht-Notruf'
      } else {
        event.target.innerHTML = '0157 71366534'
      }
    },
    toggleDropdown: function() {
      this.dropdownOpen = !this.dropdownOpen
        document.body.classList.toggle('overflow-hidden');
    }
  },
}
</script>

<style lang="scss">
@import "assets/font";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
</style>
