<template>
  <div class="text-lg leading-relaxed">
    <section class="mb-40">
      <h3 class="mb-2 text-2xl">
        <span class="block text-gray-700">Widerruf Autokredit:</span>
        Nutzen Sie unsere kostenfreie Ersteinschätzung
      </h3>
      <p class="mb-2">Der Europäische Gerichtshof hat mit Urteil vom 26. März 2020 (Az.: C-66/19) eine Widerrufsformulierung in einem Darlehensvertrag als unzulässig eingestuft, die in fast allen Finanzierungsverträgen (Autokredit, Leasing etc) verwendet wurde. Die Folge ist, dass all diese Verträge noch nach Jahren widerrufbar sind. Lassen Sie uns Ihren Vertrag prüfen und nutzen Sie Ihre Chance, Ihr Geld zurückzubekommen – jetzt!</p>
      <p class="mb-2">Wir prüfen Ihren Vertrag, ob</p>
      <ul class="mb-4">
        <li><span class="mr-1 text-gray-700">—</span> die Widerrufsbelehrung fehlerhaft ist und</li>
        <li><span class="mr-1 text-gray-700">—</span> wie viel Geld Sie von der Bank fordern können.</li>
      </ul>
      <p class="mb-4">Es bleibt allein Ihre Entscheidung, ob Sie Ihre Forderung gegen die Bank durch uns geltend machen wollen. Bis hierher ist unsere Leistung für Sie kostenlos.</p>
    </section>

    <section class="mb-40">
      <h3 class="mb-2 text-2xl">
        <span class="block text-gray-700">Der EuGH schützt Verbraucher:</span>
        Banken haben durch fehlerhafte Widerrufsbelehrung falsch informiert. Der Widerrufsjoker ist zurück!
      </h3>
      <p class="mb-4">Der Europäische Gerichtshof hat mit einem sensationellen Urteil am 26.03.2020 (Az.: C66/19) entschieden, dass die Widerrufsbelehrung, die Kaskadenverweise enthält, unzulässig ist. Diese Formulierung findet sich in nahezu allen Finanzierungsverträgen. Hiervon betroffen sind auch Autofinanzierungs- und Autoleasingverträge. Mit dieser Widerrufsbelehrung wurden Verbraucher nicht ordnungsgemäß über den Beginn der Widrrufsfrist informiert. Laufende Verträge sind noch nach Jahren widerrufbar, Verbraucher können ihr Geld zurückfordern.</p>
      <p class="ml-1 underline cursor-pointer" @click="judgementMore = !judgementMore" v-if="!judgementMore">Weiterlesen</p>

      <div v-if="judgementMore">
        <h4 class="mb-2 text-2xl">Was war der Hintergrund?</h4>
        <p class="mb-4">Wenn der Verbraucher mit seiner Bank ein Darlehen abschließt, so ist die Bank verpflichtet, den Kunden auf das Widerrufsrecht hinzuweisen und entsprechend hierüber zu belehren. Das bedeutet vor allem, dass sich aus der Widerrufsbelehrung ergeben muss, wann die Widerrufsfrist überhaupt zu laufen beginnt. Dies ist nämlich gar nicht so eindeutig, aber gleichwohl eine sogenannte „Pflichtangabe“.</p>
        <p class="mb-4">Die Folge ist: Sie können Ihr Darlehen auch heute noch widerrufen. Voraussetzung ist allerdings, dass der Autokredit & Leasingvertrag nach dem 12. Juni 2014 abgeschlossen wurden.</p>
        <h4 class="mb-2 text-2xl">Folgen des Widerrufs:</h4>
        <p class="mb-4">Bei Widerruf des Darlehens findet eine vollständige Rückabwicklung des Vertrages statt. Dies bedeutet für den Verbraucher, dass er die gezahlten Kreditraten nebst eventuell gezahlter Anzahlung vollständig zurückfordern kann. Das finanzierte Fahrzeug geht, so wie es ist, an die Bank zurück. Die Bank darf lediglich die Zinsen für die Zeit bis zum Widerruf einbehalten (wenn sie auch darüber korrekt belehrte).</p>
        <h4 class="mb-2 text-2xl">Für wen kommt ein Widerruf in Frage:</h4>
        <p class="mb-4">Interessant ist der Widerruf von Darlehensverträgen vor allem für Inhaber eines Kfz-Finanzierungsvertrags als auch Kfz-Leasingvertrags. Im Falle der Ausübung des Widerrufsrechts muss das Fahrzeug an die Bank zurückgegeben werden, es werden jedoch sämtliche bezahlte Kreditraten nebst eventuell gezahlter Anzahlung erstattet mit der Folge, dass der Verbraucher jahrelang nahezu „kostenlos“ Auto fahren konnte.</p>

        <h4 class="mb-2 text-2xl">Rechenbeispiel: Widerruf Autokredit</h4>
        <div class="p-6 mb-4 bg-green-lightMoss">
          <table class="block w-full mb-6 border-separate border-spacing sm:table">
            <tr class="flex flex-col mb-2 sm:table-row">
              <td>Kauf/Finanzierungsbeginn</td>
              <td>30.10.2017</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row">
              <td>Kaufpreis</td>
              <td>30.000 Euro</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row">
              <td>Anzahlung</td>
              <td>6.000 Euro</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row">
              <td>Finanzierungsrate</td>
              <td>400 Euro</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row">
              <td>Zins</td>
              <td>0,9 %</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row">
              <td>Zurückgelegte Kilometer</td>
              <td>36.000 km</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row">
              <td>Widerruf erfolgt</td>
              <td>{{ threeMonthDate }}</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row text-red">
              <td>Was wurde bezahlt:</td>
              <td>19.600,00 Euro</td>
            </tr>
            <tr class="flex flex-col mb-2 sm:table-row text-green-shamrock">
              <td>Was bekommt der Käufer zurück:</td>
              <td>19.150,02 Euro</td>
            </tr>
          </table>
        </div>

        <p class="mb-4">Ergebnis: Sie durften das Auto in knapp drei Jahren für insgesamt 449,98 Euro nutzen.</p>

        <p class="ml-1 underline cursor-pointer" @click="judgementMore = !judgementMore">Meniger anzeigen</p>
      </div>
    </section>

    <section class="mb-40">
      <h3 class="mb-2 text-2xl">Viele Autokreditverträge aber auch Leasingverträge beinhalten eine flasche Widerrufsbelehrung. Sie könnten diese Verträge widerrufen und Ihr Geld zurückverlangen!</h3>

      <p class="mb-4">In allen Finanzierungsverträgen (z.B. VW Bank, Audi Bank, BMW Bank, u.a.) müssen die Verbraucher über ihr Widerrufsrecht informiert werden. Deshalb finden sich in Autokreditund Leasingverträgen ähnliche oder sogar gleichlautende Formulierungen. Die meisten bisher verwendeten Formulierungen sind rechtsfehlerhaft. Das hat vor kurzem der Europäische Gerichtshof (EuGH) entschieden.</p>
      <p class="mb-4">Wenn Sie Ihren Vertrag nach dem 12. Juni 2014 unterzeichnet haben, können Sie die gezahlten Raten und auch Anzahlungen zurückerhalten.</p>
      <p class="mb-4">Die Widerrufsmöglichkeit ist für alle Autobesitzer (Benziner, Diesel, Elektro, Hybrid) interessant. Denn die Autos erleiden meist hohe Wetverluste über die Jahre. Durch Widerruf des Finanzierungsvertrages müssen Sie jedoch nur die Bankzinsen hergeben und bekommen ansonsten Ihre gesamten Anzahlungen und Tilgungsraten zurück. Für den Wertverlust des Fahrzeugs müssen Sie hingegen nicht einstehen. Auch die Ballonrate müssen Sie dann nicht zahlen und können mit dem freigewordenen Geld sich etwas Anderes gönnen.</p>
    </section>

    <section class="mb-40">
      <h3 class="mb-2 text-2xl">
        <span class="block text-gray-700">Der Widerruf:</span>
        Ein Glücksfall für Dieselfahrer
      </h3>
      <p class="mb-4">Abgasskandale und mögliche Fahrverbote machen Dieselautos immer unattraktiver. Gerade für Dieselfahrer ist der Widerruf eine einmalige Chance, das finanzierte Auto loszuwerden – unabhängig davon, ob vom Abgasskandal tatsächlich betroffen oder nicht.</p>
      <p class="mb-4">Interessant ist das auch für Dieselfahrer, die gegen VW bereits geklagt haben. Mit dem Vergleichsangebot der VW AG in der Musterfeststellungsklage müssen Sie sich nicht zufriedengeben. Sie können mehr bekommen! Sogar, wenn Sie den Vergleich bereits angenommen haben.</p>
      <p class="mb-4">Wenn Sie also Ihren Diesel loswerden wollen, dann bietet Ihnen der Widerruf eine hervorragende Möglichkeit. Wir prüfen Ihre Verträge kostenfrei auf Fehler. Über weiteres Vorgehen entscheiden Sie.</p>
    </section>

    <section class="p-8 mb-40 -m-8 bg-gray-100">
      <h3 class="mb-2 text-2xl">Ich habe Rechtsschutz. Übernimmt sie die Kosten des Widerrufs?</h3>
      <p class="mb-4">Wenn Sie rechtsschutzversichert sind, dann sollten Sie erst recht Ihren Finanzierungsvertrag von uns prüfen lassen. Die Prüfung ist für Sie kostenfrei. Nach der Ersteinschätzung kontaktieren wir Ihre Rechtsschutzversicherung. Auch das ist für Sie kostenfrei. Da wir Erfahrung mit Rechtsschutzversicherungen haben, können wir die passenden Argumente für die Kostenübernahme vorbringen und auch rechtlich begründen.</p>
      <p class="mb-4">Wenn Sie nicht rechtsschutzversichert sind, kann ein Widerruf für Sie trotzdem Sinn ergeben. Wir prüfen das!</p>
    </section>

    <section class="mb-40">
      <h3 class="mb-2 text-2xl">Widerruf Autokredit: In 3 Schritten zum Erfolg</h3>

      <div class="xl:flex">
        <div class="flex-1 p-6 mb-4 bg-green-lightMoss xl:mr-4">
          <h4 class="mb-2 text-xl">1. Prüfung des Abschlussdatums</h4>
          <p class="mb-4">Sie haben Ihr Auto nach dem 12.6.2014 gekauft und finanziert oder geleast? Dann haben Sie gute Chancen, Ihren Kredit zu widerrufen.</p>
          <p>
            <a href="#formular">
              <button
                class="px-4 py-3 mr-4 text-base transition duration-300 border-black rounded-none border-1 hover:bg-black hover:text-white"
                type="button"
              >Jetzt anfragen</button>
            </a>
          </p>
        </div>
        <div class="flex-1 p-6 mb-4 bg-green-lightMoss xl:mr-4">
          <h4 class="mb-2 text-xl">2. Kostenfreie Ersteinschätzung</h4>
          <p>Im Anschluss an den Schnelltest können Sie unsere kostenlos Ersteinschätzung anfordern. Danach entscheiden Sie, ob Sie uns beauftragen wollen. Bis dahin: keine Kosten, kein Risiko.</p>
        </div>
        <div class="flex-1 p-6 mb-4 bg-green-lightMoss">
          <h4 class="mb-2 text-xl">3. Vertrag erfolgreich widerrufen</h4>
          <p>Der Widerruf Ihrer Autofinanzierung führt dazu, dass Sie keine weiteren Raten zahlen müssen, Ihr Fahrzeug an die Bank zurückgeben können und das Geld zurückerstattet bekommen.</p>
        </div>
      </div>
    </section>

    <section class="mb-40">
      <h3 class="mb-4 text-2xl">Betroffene Banken</h3>
      <p class="mb-4">Die Verträge folgender Banken beinhalten mit hoher Wahrscheinlichkeit die fehlerhafte Widerrufsbelehrung:</p>

      <div class="flex flex-wrap mb-4 -mx-4">
        <div
          class="flex w-full px-4 pb-4 lg:w-1/3 sm:w-1/2"
          v-for="bank of $props.banks" :key="$props.banks.indexOf(bank)"
        >
          <span class="w-full pb-2 border-gray-500 border-b-1">{{ bank }}</span>
        </div>
      </div>

      <p class="mb-4">Aber auch andere Banken weisen höchstwahrscheinlich dieselben Fehler auf. Seinerzeit haben alle Banken ähnliche oder sogar gleichlautende Formulierungen benutzt, wenn Sie Verbraucher über das Widerrufsrecht informieren mussten. Ist Ihre Bank hier nicht benannt, wählen Sie bitte “Andere Bank” aus.</p>
    </section>

  </div>
</template>

<script>
export default {
  name: 'Seo',
  props: {
    banks: Array
  },
  data: function() {
    return {
      judgementMore: false
    }
  },
  computed: {
    threeMonthDate: function() {
      // const y = new Date().setFullYear
      const d = new Date()
      const inThree = d.setMonth(d.getMonth() + 3)
      return inThree.toLocaleString('default', { month: 'long', year: 'numeric' })
    }
  }
}
</script>

<style lang="scss" scoped>
.border-spacing {
  border-spacing: 0 1rem;
}
</style>
