<template>
  <div class="md:flex flex-col items-end">
    <div class="md:w-2/3 md:pl-8 mb-16">
      <h2 class="text-4xl mb-1 text-gray-700">Fehler 404 – Seite nicht gefunden</h2>
      <h2 class="text-4xl mb-4">Die von Ihnen angeforderte Seite konnte leider nicht gefunden werden.</h2>

      <a href="/" class="underline">Zurück zur Startseite</a>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
