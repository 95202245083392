<template>
    <ul class="text-gray-600" :class="{ 'flex': $props.vertical }">
        <li :class="{ 'mr-6': $props.vertical }">
            <a
                class="hover:text-black"
                href="https://www.hswrecht.de/rechtsgebiete"
                rel="noopener noreferrer"
                target="_blank"
            >Rechtsgebiete</a>
        </li>
        <li :class="{ 'mr-6': $props.vertical }">
            <a
                class="hover:text-black"
                href="https://www.hswrecht.de/rechtsanwaelte"
                rel="noopener noreferrer"
                target="_blank"
            >Rechtsanwälte</a>
        </li>
        <li :class="{ 'mr-6': $props.vertical }">
            <a
                class="hover:text-black"
                href="https://www.hswrecht.de/kanzlei"
                rel="noopener noreferrer"
                target="_blank"
            >Kanzlei</a>
        </li>
        <li>
            <a
                class="hover:text-black"
                href="https://www.hswrecht.de/kontakt"
                rel="noopener noreferrer"
                target="_blank"
            >Kontakt</a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Links',
    props: {
        vertical: Boolean
    }
}
</script>
