import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Error404 from '../views/404.vue'
import FormError from '../views/FormError.vue'
import FormSuccess from '../views/FormSuccess.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: Error404
  },
  {
    path: '/',
    name: 'Main',
    component: Home
  },
  {
    path: '/success',
    name: 'Erfolgreich',
    component: FormSuccess
  },
  {
    path: '/error',
    name: 'Fehler',
    component: FormError
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
