<template>
  <form class="text-lg">
    <div class="mb-4 leading-tight">
      <h2 class="mb-2 text-4xl">Jetzt kostenlosen Schnelltest machen</h2>
      <p class="mb-4 text-lg">Beantworten Sie 8 einfache Fragen und wir sagen Ihnen, wie die Erfolgschancen stehen, Ihren Autokredit- oder Leasingvertrag zu widerrufen.</p>
      <p class="mb-4 text-gray-600" v-if="step <= maxSteps">Schritt {{ step }}/{{ maxSteps }}</p>
    </div>

    <div class="mb-6" v-if="step === questions.paymentType.id">
      <h3 class="mb-2 font-semibold">Wie wurde Ihr Fahrzeug bezahlt?</h3>
      <label class="block mb-1">
        <input type="radio" value="leasing" v-model="questions.paymentType.value">
        <span class="ml-2">Leasing</span>
      </label>
      <label class="block">
        <input type="radio" value="finanzierung" v-model="questions.paymentType.value">
        <span class="ml-2">Finanzierung</span>
      </label>
    </div>

    <div class="mb-6" v-if="step === questions.bank.id">
      <h3 class="mb-2 font-semibold">Bei welcher Bank haben Sie Ihr Fahrzeug {{ getPaymentType }}?</h3>

      <select
        class="w-full max-w-xs px-2 px-3 py-2 py-3 mb-4 leading-tight bg-white border-black appearance-none border-1 focus:shadow-outline"
        v-model="questions.bank.value"
      >
        <option v-for="bank of banksCustom" :key="banks.indexOf(bank)">{{ bank }}</option>
      </select>

      <input
        class="block w-full max-w-xs px-2 px-3 py-2 py-3 mb-4 -mt-2 leading-tight bg-white border-black appearance-none border-1 focus:shadow-outline"
        type="text"
        v-if="hasCustomBankValue()"
        v-model="questions.bankCustom.value"
      >
    </div>

    <div class="mb-6" v-if="step === questions.usedType.id">
      <h3 class="mb-2 font-semibold">Handelte es sich beim Erwerb um einen Neu- oder Gebrauchtwagen?</h3>
      <label class="block mb-1">
        <input type="radio" value="neuwagen" v-model="questions.usedType.value">
        <span class="ml-2">Neuwagen</span>
      </label>
      <label class="block">
        <input type="radio" value="gebrauchtwagen" v-model="questions.usedType.value">
        <span class="ml-2">Gebrauchtwagen</span>
      </label>
    </div>

    <div class="mb-6" v-if="step === questions.afterDate.id">
      <h3 class="mb-2 font-semibold">Haben Sie den Kreditvertrag nach dem 13. Juni 2014 geschlossen?</h3>
      <label class="block mb-1">
        <input type="radio" :value="true" v-model="questions.afterDate.value">
        <span class="ml-2">Ja, nach dem 13. Juni 2014</span>
      </label>
      <label class="block">
        <input type="radio" :value="false" v-model="questions.afterDate.value">
        <span class="ml-2">Nein, vor dem 13. Juni 2014</span>
      </label>
    </div>

    <div class="mb-6" v-if="step === questions.activeContract.id">
      <h3 class="mb-2 font-semibold">Läuft Ihr Kreditvertrag noch?</h3>
      <label class="block mb-1">
        <input type="radio" :value="true" v-model="questions.activeContract.value">
        <span class="ml-2">Ja, der Vertrag ist noch aktiv</span>
      </label>
      <label class="block">
        <input type="radio" :value="false" v-model="questions.activeContract.value">
        <span class="ml-2">Nein, der Vertrag ist bereits ausgelaufen</span>
      </label>
    </div>

    <div class="mb-6" v-if="step === questions.owner.id">
      <h3 class="mb-2 font-semibold">Ist das Fahrzeug noch in Ihrem Besitz?</h3>
      <label class="block mb-1">
        <input type="radio" :value="true" v-model="questions.owner.value">
        <span class="ml-2">Ja</span>
      </label>
      <label class="block">
        <input type="radio" :value="false" v-model="questions.owner.value">
        <span class="ml-2">Nein</span>
      </label>
    </div>

    <div class="mb-6" v-if="step === questions.legalInsurance.id">
      <h3 class="mb-2 font-semibold">Haben Sie eine Rechtsschutzversicherung?</h3>
      <label class="block mb-1">
        <input type="radio" :value="true" v-model="questions.legalInsurance.value">
        <span class="ml-2">Ja</span>
      </label>
      <label class="block">
        <input type="radio" :value="false" v-model="questions.legalInsurance.value">
        <span class="ml-2">Nein</span>
      </label>
    </div>

    <div class="mb-6" v-if="step === questions.usageType.id">
      <h3 class="mb-2 font-semibold">Nutzen Sie das Fahrzeug haupt­sächlich privat oder geschäftlich?</h3>
      <label class="block mb-1">
        <input type="radio" value="private" v-model="questions.usageType.value">
        <span class="ml-2">Privat</span>
      </label>
      <label class="block">
        <input type="radio" value="geschäftlich" v-model="questions.usageType.value">
        <span class="ml-2">Geschäftlich</span>
      </label>
    </div>

    <div class="mb-8">
      <button
        @click="prevStep()"
        class="px-4 py-3 mr-4 text-base transition duration-300 border-black rounded-none border-1 hover:bg-black hover:text-white"
        type="button"
        v-if="step > 1"
      >← Zurück</button>

      <button
        :class="{ 'opacity-25 cursor-default': stepInvalid, 'hover:bg-black hover:text-white': !stepInvalid }"
        :disabled="stepInvalid"
        @click="nextStep()"
        class="px-4 py-3 text-base transition duration-300 border-black rounded-none border-1"
        type="button"
        v-if="step < maxSteps + 1"
      >Weiter →</button>

      <button
        @click="restart()"
        class="px-4 py-3 text-base transition duration-300 border-black rounded-none border-1 hover:bg-black hover:text-white"
        type="button"
        v-if="step > maxSteps"
      >Neuen Test starten</button>
    </div>

    <div class="mb-6" v-if="step === maxSteps + 1">
      <div class="mb-6">
        <div v-if="unavailable">
          <h2 class="mb-2 text-3xl">Auf Basis Ihrer Antworten ist ein Widerruf Ihres Kreditvertrages höchstwahrscheinlich <span class="font-semibold">nicht</span> möglich.</h2>
        </div>
        <div v-if="!unavailable">
          <h2 class="mb-2 text-4xl">Ihre Chancen stehen sehr gut! Ein Widerruf Ihres Kreditvertrags ist höchstwahrscheinlich möglich.</h2>
          <p class="mt-4">Die detailliertere Einschätzung erhalten Sie kostenfrei von unseren Anwälten.</p>

          <h3 class="mb-2 text-2xl">So geht’s weiter</h3>
          <p class="mb-4">Geben Sie Ihre Kontaktdaten ein, damit wir Sie zu Ihrem Fall näher beraten können. Einer unserer Anwälte wird sich innerhalb von 24 Stunden bei Ihnen per Mail oder Telefon melden. Hierfür entstehen Ihnen keinerlei Kosten.</p>

          <!-- Contact Form -->
          <div class="max-w-2xl mt-12">

            <div class="mb-4">
              <span class="mr-8">Anrede</span>
              <label class="mr-2">
                <input type="radio" value="Frau" v-model="contact.salutation">
                <span class="ml-2">Frau</span>
              </label>
              <label class="">
                <input type="radio" value="Herr" v-model="contact.salutation">
                <span class="ml-2">Herr</span>
              </label>
            </div>

            <div class="mb-4 md:flex">
              <label class="block w-full mb-4 md:mb-0 md:mr-8">
                <div class="mb-1 font-bold">Vorname</div>
                <input class="w-full px-3 py-2 leading-tight border-black appearance-none border-1 focus:shadow-outline" type="text" v-model="contact.firstname" />
              </label>
              <label class="block w-full">
                <div class="mb-1 font-bold">Nachname</div>
                <input class="w-full px-3 py-2 leading-tight border-black appearance-none border-1 focus:shadow-outline" type="text" v-model="contact.name" />
              </label>
            </div>

            <div class="mb-4 md:flex">
              <label class="block w-full">
                <div class="mb-1 font-bold">E-Mail</div>
                <input class="w-full px-3 py-2 leading-tight border-black appearance-none border-1 focus:shadow-outline" type="email" v-model="contact.email" />
              </label>
            </div>

            <div class="mb-4 md:flex">
              <label class="block w-full">
                <div class="mb-1 font-bold">Telefonnummer <span class="italic text-gray-700">- optional</span></div>
                <input class="w-full px-3 py-2 leading-tight border-black appearance-none border-1 focus:shadow-outline" type="tel" v-model="contact.phone" />
              </label>
            </div>

            <div class="mb-4 md:flex">
              <label class="block w-full">
                <div class="mb-1 font-bold">Zusätzliche Informationen <span class="italic text-gray-700">- optional</span></div>
                <textarea class="w-full px-3 py-2 leading-tight border-black appearance-none border-1 focus:shadow-outline" type="text" v-model="contact.comment"></textarea>
              </label>
            </div>

            <div class="mb-4 md:flex">
              <label class="block w-full">
                <input type="checkbox" v-model="contact.privacyConfirm" />
                <span class="ml-2 font-bold">Ja, ich habe die <a href="https://www.hswrecht.de/datenschutz" target="_blank" rel="noopener noreferrer" class="underline">Daten­schutz­erklärung</a> zur Kenntnis genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben, gespeichert und zweckgebunden zur Bearbeitung und Beantwortung meiner Anfrage verwendet werden.</span>
              </label>
            </div>

          </div>

          <VueRecaptcha class="mb-4" :sitekey="sitekey" :loadRecaptchaScript="true" @verify="validateCaptcha" @expired="resetCaptcha"></VueRecaptcha>

          <button
            :class="{ 'opacity-25 cursor-default': !contactValid || loading, 'hover:bg-black hover:text-white': contactValid && !loading }"
            :disabled="!contactValid || loading"
            @click="send()"
            class="px-4 py-3 text-base transition duration-300 border-black rounded-none border-1"
            type="button"
          >Abschicken →</button>

        </div>

      </div>
    </div>

  </form>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

const initialQuestions = {
  paymentType: { value: null, id: 1 },
  bank: { value: null, id: 2 },
  bankCustom: { value: null, id: 2 },
  usedType: { value: null, id: 3 },
  afterDate: { value: null, id: 4 },
  activeContract: { value: null, id: 5 },
  owner: { value: null, id: 6 },
  legalInsurance: { value: null, id: 7 },
  usageType: { value: null, id: 8 },
}

export default {
  name: 'Form',
  components: {
    VueRecaptcha
  },
  props: {
    banks: Array
  },
  data: function() {
    return {
      step: 1,
      questions: {},
      banksCustom: [],
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      loading: false,
      contact: {
        salutation: 'Frau',
        firstname: null,
        name: null,
        email: null,
        phone: undefined,
        comment: undefined,
        privacyConfirm: null,
        captcha: null
      }
    };
  },
  beforeMount: function() {
    this.questions = JSON.parse(JSON.stringify(initialQuestions))
    this.banksCustom = this.$props.banks;
  },
  computed: {
    maxSteps: function() {
      return Math.max.apply(Math, Object.keys(this.questions).map(key => this.questions[key].id))
    },
    unavailable: function() {
      return !this.questions.afterDate && !this.questions.activeContract && this.questions.usageType !== 'private'
    },
    getPaymentType: function() {
      return this.questions.paymentType.value === 'leasing' ? 'geleast' : 'finanziert';
    },
    contactValid: function() {
      return Object.keys(this.contact).every(key => ![null, false].includes(this.contact[key]))
    },
    stepInvalid: function() {
      let currentQuestions = [];

      for (const key of Object.keys(this.questions)) {
        if (this.questions[key].id === this.step) {
          currentQuestions.push(this.questions[key])
        }
      }

      return currentQuestions.some(el => [undefined, null, ''].includes(el.value));
    }
  },
  methods: {
    scrollToTop: function() {
      window.scrollTo(0,0);
    },
    hasCustomBankValue: function() {
      const customBank = this.questions.bank.value === 'Andere Bank'
      // console.debug(customBank, JSON.stringify(this.questions.bankCustom.value), JSON.stringify(this.questions.bank.value))
      if (!this.questions.bankCustom.value) {
        this.questions.bankCustom.value = customBank ? null : false
      }
      return customBank
    },
    nextStep: function() {
      if (this.step <= this.maxSteps) {
        this.step++
      }
    },
    prevStep: function() {
      if (this.step > 0) {
        this.step--
      }
    },
    restart: function() {
      this.step = 1
      this.questions = JSON.parse(JSON.stringify(initialQuestions))
    },
    resetCaptcha: function() {
      this.contact.captcha = null
    },
    validateCaptcha: function(captcha) {
      this.contact.captcha = captcha
    },
    send: async function() {
      try {
        const sendData = {
          activeContract: this.questions.activeContract.value,
          afterDate: this.questions.afterDate.value,
          bank: this.questions.customBank ? this.questions.customBank.value : this.questions.bank.value,
          contact: this.contact,
          legalInsurance: this.questions.legalInsurance.value,
          owner: this.questions.owner.value,
          paymentType: this.questions.paymentType.value,
          usageType: this.questions.usageType.value,
          usedType: this.questions.usedType.value,
        };

        console.debug(sendData);

        this.loading = true

        const response = await fetch('process.php', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(sendData)
        })

        const data = await response.json()

        this.loading = false

        await this.$router.push(data.status);
        this.scrollToTop();
      } catch (error) {
        console.error(error);
        await this.$router.push('error');
      }
    },
  },
}
</script>
